import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import breakDownAllNodes from "utils/breakDownAllNodes";
import Wrapper from "containers/Wrapper";
import Founders from "containers/Founders";
import nl2br from "utils/nl2br";

export const query = graphql`
  query AboutQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          imageFileName
          anchor
          sections {
            anchor
            header
            content
          }
          copyright
          social {
            linkedin
          }
          privacyText
          termsText
          
          titleTemplate
          keywords
          description
          backgroundImg
          
          buttonText
          
          header
          subheader
          founders {
            imageFileName
            header
            content
            social {
              linkedin
            }
          }
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const AboutPage = ({ pathContext, data }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const {
    aboutNode,
    foundersNode,
  } = breakDownAllNodes(nodes);

  const { frontmatter: { sections } } = aboutNode;


  return (
    <Wrapper
      pathContext={pathContext}
      nodes={nodes}
      node={aboutNode}
    >
      <div>
        {sections.map(({ anchor, header, content }, i) => {
          return header ? (
            <div key={header} id={anchor} className={
              `h-custom ${i % 2 === 1 ? "bg-white-transparent text-dark" : ''}`
            }>
              <Container className='py-5'>
                <h1 className='text-center mb-5 header-text'>{header}</h1>
                <p
                  className='lead text-center section-text'
                  dangerouslySetInnerHTML={{ __html: nl2br(content) }}
                />
              </Container>
            </div>
          ) : null;
        })}

        <Founders
          frontmatter={foundersNode.frontmatter}
          pathContext={pathContext}
        />
      </div>
    </Wrapper>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

AboutPage.defaultProps = {
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default AboutPage;
