import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Container, Row } from "react-bootstrap";

import Founder from "components/Founder";
import getBaseUrl from "utils/getBaseUrl";

const Founders = ({ frontmatter, pathContext: { defaultLang, langKey } }) => {
  if (!frontmatter) return null;

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    founders,
    buttonText,
  } = frontmatter;

  return (
    <div className='py-5' style={{ background: "rgba(33, 37,41, .9)" }} id={anchor}>
      <Container className='py-5'>
        <div className='text-center mb-5'>
          <h1>{rootHeader}</h1>
          <h2 className='text-primary'>{rootSubHeader}</h2>
        </div>

        <Row className='justify-content-center'>
          {founders.map(({ header, imageFileName, ...props }) => (
            <Founder
              key={header}
              header={header}
              imageFileName={imageFileName} {...props}
            />
          ))}
        </Row>

        <div className='text-center mt-5'>
          <Link
            to={`${getBaseUrl(defaultLang, langKey)}contact/`}
            className="btn btn-lg btn-primary text-uppercase px-4 py-2"
          >{buttonText}</Link>
        </div>
      </Container>
    </div>
  );
};

Founders.propTypes = {
  frontmatter: PropTypes.object,
  pathContext: PropTypes.object.isRequired,
};

Founders.defaultProps = {
  frontmatter: null,
};

export default Founders;
