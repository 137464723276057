import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import * as SocialIcons from "components/SocialIcons";
import Image from "components/Image";
import nl2br from "utils/nl2br";

const Founder = ({
  imageFileName, imageAlt, header, social: { linkedin }, content
}) => {
  const linkedinPart = linkedin ?
    <SocialIcons.Linkedin className='mr-3' userName={linkedin}/> : null;

  return (
    <Col key={header} lg={6} className='my-3'>
      <div className='text-center'>
        <Image
          className='mx-auto rounded-circle'
          style={{ width: "150px" }}
          fileName={imageFileName}
          imageAlt={imageAlt}
        />
        <h3 className='my-4'>
          {linkedinPart}
          {header}
        </h3>
      </div>
      <p className='text-white-70' dangerouslySetInnerHTML={{ __html: nl2br(content) }}/>
    </Col>
  );
};

Founder.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  social: PropTypes.shape({
    linkedin: PropTypes.string,
  }),
  content: PropTypes.string,
};

Founder.defaultProps = {
  imageAlt: null,
  header: "",
  social: {
    linkedin: null,
  },
  content: "",
};

export default Founder;
